import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  RelationshipField,
  RelationshipFieldMany,
  StringField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  RelationshipProperty,
  RelationshipPropertyMany,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class Credit extends IppyDippyCrudModel {
  protected static _typeLabel = "Credit";
  protected static asProperty = "credit";
  public static api = {
    path: "credits",
  };
  protected static routeBase = "credits";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = ["entry_author"];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Credit Logged At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "earned_at",
        label: "Date Earned",
      },
    },

    {
      type: RelationshipProperty,
      opts: {
        name: "content",
        label: "Content",
        relatedModel: "Content",
        computed: true,
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "content_engagement",
        label: "Content Engagment",
        relatedModel: "ContentEngagement",
        userPermissions: new UserPermissions(
          {
            [UserRole.SuperAdmin]: UserPermission.Edit,
            [UserRole.EntityOwner]: UserPermission.Edit,
          },
          UserPermission.Hidden
        ),
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "label",
        label: "Label",
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: NumberProperty,
      opts: {
        name: "credits",
        label: "Credit Amount",
        description: "(in minutes)",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        foreignProperty: "credits",
      },
    },
    {
      type: RelationshipPropertyMany,
      opts: {
        name: "attachments",
        label: "Attachments",
        relatedModel: "Attachment",
        foreignProperty: "credit",
      },
    },
    {
      type: RelationshipProperty,
      opts: {
        name: "entry_author",
        label: "Logged By",
        relatedModel: "User",
        foreignProperty: "credits",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "licenses",
        label: "Toward Licenses",
        relatedModel: "License",
        foreignProperty: "credits",
        computed: true,
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "content_categories",
        label: "Categories",
        relatedModel: "ContentCategory",
        foreignProperty: "credits",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    { type: "DateField", opts: { property: "earned_at" } },
    { type: RelationshipField, opts: { property: "content" } },
    { type: RelationshipField, opts: { property: "content_engagement" } },
    { type: StringField, opts: { property: "label" } },
    { type: StringField, opts: { property: "description" } },
    { type: NumberField, opts: { property: "credits" } },
    { type: RelationshipField, opts: { property: "user" } },
    { type: RelationshipField, opts: { property: "entry_author" } },
    { type: RelationshipFieldMany, opts: { property: "attachments" } },
    { type: RelationshipFieldMany, opts: { property: "content_categories" } },
    {
      type: RelationshipFieldMany,
      opts: {
        property: "licenses",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: [
        "content",
        "label",
        "credits",
        "user",
        "earned_at",
        "entry_author",
      ],
      dynamicFilterOptions: {
        filterFields: [
          "content",
          "content_engagement.content",
          "label",
          "licenses",
          "credits",
          "earned_at",
          "user",
          "entry_author",
          "created_at",
          "updated_at",
          "description",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: [
            "user",
            "earned_at",
            "content",
            "label",
            "description",
            "credits",
            "content_engagement",
            "content_categories",
          ],
        },
        {
          id: "1",
          fields: ["attachments", "licenses"],
        },
        {
          id: "2",
          fields: ["created_at", "updated_at", "entry_author"],
        },
      ],
    },
  ];
}
