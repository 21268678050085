import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";

import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class License extends IppyDippyCrudModel {
  protected static _typeLabel = "License";
  protected static _typeLabelPlural = "Licenses";
  protected static asProperty = "licenses";
  public static api = {
    path: "licenses",
  };
  protected static routeBase = "licenses";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: "NumberProperty",
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Entry Created At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "DateTimeProperty",
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "name",
        label: "Name",
        rules: ["required"],
      },
    },
    {
      type: "StringProperty",
      opts: {
        name: "description",
        label: "Description",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "start_date",
        label: "Start Date",
      },
    },
    {
      type: "DateProperty",
      opts: {
        name: "end_date",
        label: "End Date",
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "target_credits",
        label: "Target Credits",
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_total_credits",
        label: "Total Credits",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_benchmark_progress_credits",
        label: "Benchmark",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_benchmark_progress_percent",
        label: "Benchmark %",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_current_progress",
        label: "Progress %",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "NumberProperty",
      opts: {
        name: "cache_benchmark_deviation",
        label: "Benchmark Deviation",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "JsonProperty",
      opts: {
        name: "cache_categories_percentages",
        label: "Category Breakdown",
        userPermissions: UserPermission.Read,
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
      },
    },
    {
      type: "RelationshipPropertyMany",
      opts: {
        name: "credits",
        label: "Credits",
        relatedModel: "Credit",
        foreignProperty: "licenses",
        computed: true,
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: "NumberField", opts: { property: "id" } },
    {
      type: "DateTimeField",
      opts: { property: "created_at" },
    },
    { type: "DateTimeField", opts: { property: "updated_at" } },
    { type: "DateTimeField", opts: { property: "deleted_at" } },
    {
      type: "StringField",
      opts: { property: "name", isPrimaryLabelField: true },
    },
    {
      type: "LongTextField",
      opts: { property: "description" },
    },
    {
      type: "DateField",
      opts: { property: "start_date" },
    },
    {
      type: "DateField",
      opts: { property: "end_date" },
    },
    {
      type: "NumberField",
      opts: { property: "target_credits" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_total_credits" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_progress_credits" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_progress_percent" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_current_progress" },
    },
    {
      type: "NumberField",
      opts: { property: "cache_benchmark_deviation" },
    },
    {
      type: "StringField",
      opts: {
        property: "cache_categories_percentages",
        cols: 12,
        viewComponentName: "license-progress-bar",
        formComponentName: "license-progress-bar",
      },
    },
    {
      type: "RelationshipField",
      opts: { property: "user" },
    },
    {
      type: "RelationshipFieldMany",
      opts: {
        property: "credits",
        viewComponentName: "form-relationship-field-many-table",
        formComponentName: "form-relationship-field-many-table",
      },
    },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      columnFields: [
        "name",
        "start_date",
        "end_date",
        "user",
        "cache_total_credits",
        "cache_current_progress",
        "cache_benchmark_deviation",
      ],
      enableSearch: true,
      enableDynamicFilters: true,
      useModal: true,
      dynamicFilterOptions: {
        filterFields: [
          "name",
          "description",
          "start_date",
          "end_date",
          "user",
          "cache_total_credits",
          "created_at",
          "updated_at",
          "cache_current_progress",
          "cache_benchmark_progress_percent",
          "cache_benchmark_progress_credits",
          "cache_benchmark_deviation",
        ],
      },
      defaultSort: [
        {
          field: "label",
          order: "asc",
        },
      ],
    },
  ];

  protected static modelLayoutDefinitions = [
    {
      id: "Crudform",
      fieldSets: [
        {
          id: "0",
          fields: ["name", "start_date", "end_date", "user", "description"],
        },
        {
          id: "1",
          fields: [
            "cache_categories_percentages",
            "target_credits",
            "cache_total_credits",
            "cache_current_progress",
            "cache_benchmark_progress_percent",
            "cache_benchmark_progress_credits",
            "cache_benchmark_deviation",
            "credits",
          ],
        },
        {
          id: "meta",
          fields: ["created_at", "updated_at", "id"],
        },
      ],
    },
  ];
}
