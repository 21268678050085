import { getAsChoices, GroupRole } from "../enums";
import {
  UserPermission,
  UserPermissions,
  UserRole,
} from "../plugins/Cruxtify/UserPermission";
import {
  DateTimeField,
  NumberField,
  SelectField,
} from "../plugins/Cruxtify/field-types";

import {
  DateTimeProperty,
  NumberProperty,
  StringProperty,
} from "../plugins/Cruxtify/property-types";
import { IppyDippyCrudModel } from "./IppyDippyCrudModel";

export class GroupUser extends IppyDippyCrudModel {
  protected static _typeLabel = "Group User";
  protected static asProperty = "group_user";
  public static api = {
    path: "group-users",
  };
  protected static routeBase = "group-users";
  protected static userPermissions = new UserPermissions(
    {
      [UserRole.SuperAdmin]: UserPermission.Edit,
      [UserRole.EntityOwner]: UserPermission.Edit,
      [UserRole.LoggedIn]: UserPermission.New,
    },
    UserPermission.Read
  );

  protected userIdField = [];

  protected static propertyDefinitions = [
    {
      type: NumberProperty,
      opts: {
        name: "id",
        label: "ID",
        userPermissions: UserPermission.Hidden,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "created_at",
        displayRelative: true,
        label: "Added to Group At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "updated_at",
        displayRelative: true,
        label: "Entry Updated At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: DateTimeProperty,
      opts: {
        name: "deleted_at",
        displayRelative: true,
        label: "Entry Deleted At",
        userPermissions: UserPermission.Read,
        computed: true,
      },
    },
    {
      type: StringProperty,
      opts: {
        name: "role",
        label: "Group Role",
      },
    },

    {
      type: "RelationshipProperty",
      opts: {
        name: "user",
        label: "User",
        relatedModel: "User",
        foreignProperty: "group_users",
      },
    },
    {
      type: "RelationshipProperty",
      opts: {
        name: "group",
        label: "Group",
        relatedModel: "Group",
        foreignProperty: "group_users",
      },
    },
  ];

  protected static fieldDefinitions = [
    { type: NumberField, opts: { property: "id" } },
    {
      type: DateTimeField,
      opts: { property: "created_at" },
    },
    { type: DateTimeField, opts: { property: "updated_at" } },
    { type: DateTimeField, opts: { property: "deleted_at" } },
    {
      type: SelectField,
      opts: {
        property: "role",
        cols: 4,
        options: getAsChoices(GroupRole),
        default: GroupRole.Member,
      },
    },
    { type: "RelationshipField", opts: { property: "user" } },
    { type: "RelationshipField", opts: { property: "group" } },
  ];

  protected static collectionLayoutDefinitions = [
    {
      id: "table",
      useModal: true,
      columnFields: ["created_at", "group", "user", "role"],
      dynamicFilterOptions: {
        filterFields: [
          "user",
          "user.first_name",
          "user.last_name",
          "group",
          "created_at",
          "updated_at",
        ],
      },
      defaultSort: [
        {
          field: "created_at",
          order: "desc",
        },
      ],
    },
  ];
}
