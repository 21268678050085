export enum GroupRole {
  Admin = "admin",
  Member = "member",
}

export function getAsChoices<T extends Record<string, string>>(
  enumType: T
): { [key in T[keyof T]]: keyof T } {
  return Object.keys(enumType).reduce((acc, key) => {
    acc[enumType[key as keyof T] as T[keyof T]] = key as keyof T;
    return acc;
  }, {} as { [key in T[keyof T]]: keyof T });
}
